import React from 'react';
import { graphql } from 'gatsby';

import { filterSection } from '../utils/utils';
import { Layout, SEO } from '../components';
import { CountdownTimer } from '../components/UI/FlipClock';
import {
    FeaturedDocuments,
    FAQsSection,
    HeroSection,
    HookSection,
    InfoSection,
    ModulesSection,
    TutorfairReviewCarousel,
} from '../sections';
import VideoPlayer from '../components/VideoPlayer';
import { HomepageSchema } from '../constants/schemas';

const IndexPage = ({
    data: {
        tutorfairReviews,
        datoCmsPage: homePage,
        allDatoCmsDocument: documents,
        timers,
        questions,
    },
}) => {
    const sectionData = {
        hero: filterSection('customContent', 'hero', homePage),
        info: filterSection('customContent', 'info', homePage),
        hook: filterSection('customContent', 'hook', homePage),
        modules: filterSection('section', 'modules', homePage),
        about: filterSection('section', 'about-author', homePage).section[0],
        faqs: filterSection('section', 'faqs', homePage).section[0],
        featuredDocuments: documents.edges,
    };

    const orderedTimers = [...timers.edges];
    orderedTimers.sort((a, b) => {
        if (a.node.title < b.node.title) {
            return -1;
        }
        if (a.node.title > b.node.title) {
            return 1;
        }
        return 0;
    });

    const { video, youtubeVideo, videos } = homePage;

    sectionData.featuredDocuments.sort((a, b) => {
        const aPageOrder = a.node.isfeaturedonhomepageorder;
        const bPageOrder = b.node.isfeaturedonhomepageorder;
        if (aPageOrder === null) return 1;
        if (bPageOrder === null) return -1;
        return aPageOrder - bPageOrder;
    });

    const allVideos = [
        ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
        ...(youtubeVideo ? youtubeVideo.split(',') : []),
        ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
    ];

    return (
        <Layout>
            <SEO
                canonicalUrl="https://www.mymathscloud.com"
                schema={HomepageSchema}
                title={homePage.seo.title}
                description={homePage.seo.description}
                image={homePage.seo.image}
                seoKeywords={homePage.seoKeywords}
            />
            <div className="srow centered">
                {orderedTimers?.map(({ node: { endDate, title } }) => (
                    <div className="column narrow mb-1">
                        <CountdownTimer endDate={endDate} title={title} />
                    </div>
                ))}
            </div>
            <HeroSection data={sectionData.hero} />
            {allVideos.length ? (
                <div className="header">
                    <div className="container-flex flex-col">
                        <h2 className="text-center mb-3">Featured Video{allVideos?.length > 1 ? 's' : ''}</h2>
                        <div class="video-row">
                            {allVideos.map((src) => (
                                <VideoPlayer src={src} />
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
            <FeaturedDocuments data={sectionData.featuredDocuments} />
            {/* <InfoSection data={sectionData.info} /> */}
            <TutorfairReviewCarousel data={tutorfairReviews} />
            <HookSection data={sectionData.hook} />
            <ModulesSection data={sectionData.modules} />
            <FAQsSection data={sectionData.faqs} />
        </Layout>
    );
};

export default IndexPage;

const query = graphql`
  query IndexQuery {
    datoCmsPage(slug: { eq: "home-page" }) {
      id
      title
      slug
      video {
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      youtubeVideo
      section {
        name
        section {
          ... on DatoCmsSection {
            id
            title
            subtitle
            description
            image {
              url
              alt
            }
            collapsibleBlock {
              description
              title
            }
          }
          ... on DatoCmsModule {
            id
            title
            slug
            position
            comingSoon
            shortDescription
            description
            image {
              alt
              url
            }
            subCategory {
              name
              slug
            }
          }
        }
      }
      customContent {
        name
        subtitle
        title
        description
        description2
        images {
          alt
          url
        }
      }
      seoKeywords {
        id
        keyword
      }
      seo {
        description
        image {
          url
        }
        title
      }
    }
    allDatoCmsDocument(filter: { isfeaturedonhomepage: { eq: true } }) {
      edges {
        node {
          id
          fileUrl
          hasPassword
          externalUrl
          freeSample
          freeDocument
          description
          name
          title
          keywords
          isfeaturedonhomepage
          isfeaturedonhomepageorder
          previewUrl
          fileDescription
          module {
            slug
          }
          documentCategory {
            id
            name
            slug
          }
        }
      }
    }
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: "home-page" } }) {
      edges {
        node {
          title
          endDate
        }
      }
    }
    questions: allDatoCmsQuestionOfTheDay(filter: { active: { eq: true } }) {
      edges {
        node {
          fileName
          link
          shortDescription
          title
          originalId
          file {
            url
          }
          callToActionText
        }
      }
    }
    tutorfairReviews: allDatoCmsTutorfairReview(
      filter: { shownInHomePage: { eq: true } }
      sort: { fields: reviewSortOrder, order: ASC }
    ) {
      edges {
        node {
          shortDescription
          reviewer
          originalId
          longComment
        }
      }
    }
  }
`;
export { query };
